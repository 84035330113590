// 'use client';
import { useState, useContext, useMemo } from "react";
import {   
  AreaChart, Card, Title, Select, SelectItem, 
  Tab, TabGroup, TabList, TabPanel, TabPanels, Text, Metric
} from "@tremor/react";

// Import chart components specifically
//import { LineChart } from "@tremor/react";
import { BarChart } from "@tremor/react";

//import * as Select from '@radix-ui/react-select';
//import { ChevronDownIcon, CheckIcon } from 'lucide-react';

import { GlobalContext } from "./Cars";
import { useQuery } from '@tanstack/react-query';

export default function DashGrid() {
  //Fetch the dashboard data here using reactQuery
  return (
    <div>
      <div >
        <CreditCollectionOverview />
      </div>
      <div className="w-full sm:w-1/2 lg:w-1/3 p-2">
        <StockTrendsAreaChart />
      </div>
      <div className="w-full sm:w-1/2 lg:w-1/3 p-2">
        <SalesTrendBarChart />
      </div>
      <div className="w-full sm:w-1/2 lg:w-1/3 p-2">
        <OutstandingCreditChart />
      </div>
    </div>
  );
}

const StockTrendsAreaChart = () => {
  const { DealershipID } = useContext(GlobalContext);
  const [months, setMonths] = useState(3); // Default: Last 3 months
  const { data: chartData = [], isLoading, isError } = useQuery({
    queryKey: ["stockTrend", DealershipID, months],
    queryFn: async () => {
      const response = await fetch("https://api.autodealerug.com/getstocktrend", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        credentials: "include",
        body: JSON.stringify({
          DealershipID,
          months,
        })
      });
      const result = await response.json();
      return result.data.map((item) => ({
        date: new Date(item.StockDate).toLocaleDateString("en-GB"),
        LocalStock: item.LocalStock,
        ImportedStock: item.ImportedStock,
      }));
    },
    staleTime: 5 * 60 * 1000, // Cache data for 5 minutes
    refetchOnWindowFocus: false, // Prevent refetching on window focus
  });

  if (isLoading) {
    return <Text>Loading stock data...</Text>;
  }

  if (isError) {
    return <Text color="red">Error fetching stock data</Text>;
  }

  return (
    <Card>
      <div className="flex justify-between items-center">
        <Title className='flex-1'>{`Stock: ${chartData.length ? chartData[chartData.length - 1].LocalStock + chartData[chartData.length - 1].ImportedStock : 0} Cars`}</Title>
        <Select className='flex-1' value={months.toString()} onValueChange={(value) => setMonths(Number(value))}>
          {[1, 2, 3, 4, 5, 6].map((num) => (
            <SelectItem key={num} value={num.toString()}>
              Last {num} {num === 1 ? "month" : "months"}
            </SelectItem>
          ))}
        </Select>
      </div>

      <AreaChart
        className="h-[210px]"
        data={chartData}
        index="date"
        categories={["LocalStock", "ImportedStock"]}
        colors={["blue", "red"]}
        yAxisLabel="Stock level"
        yAxisWidth={20}
        showLegend={true}
        showAnimation={true}
        showGridLines={true}
        curveType="monotone"
      />
    </Card>
  );
};

const SalesTrendBarChart = () => {

  const [months, setMonths] = useState(1);
  const [data, setChartData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const { DealershipID } = useContext(GlobalContext);
  const { data: chartData = [], isLoading, isError } = useQuery({
    queryKey: ["salesTrend", DealershipID, months],
    queryFn: async () => {
      const response = await fetch("https://api.autodealerug.com/getsalestrend", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        credentials: "include",
        body: JSON.stringify({
          DealershipID,
          months,
        })
      });
      const result = await response.json();
      return result.data.map((item) => ({
        WeekStart: item.WeekStart,
        Local: Number(item.Local_Cash) + Number(item.Local_Credit),
        Imported: Number(item.Imported_Cash) + Number(item.Imported_Credit),
        Cash: Number(item.Local_Cash) + Number(item.Imported_Cash),
        Credit: Number(item.Local_Credit) + Number(item.Imported_Credit),
      }));
    },
    staleTime: 5 * 60 * 1000, // Cache data for 5 minutes
    refetchOnWindowFocus: false, // Prevent refetching on window focus
  });

  if (isLoading) {
    return <Text>Loading sales data...</Text>;
  }

  if (isError) {
    return <Text color="red">Error fetching sales data</Text>;
  }

  return (
    <Card>
      <div className="flex justify-between items-center">
        <Title className="font-medium flex-1">Weekly Sales</Title>
        <Select
          value={months}
          onValueChange={(value) => setMonths(Number(value))}
          className="flex-1"
        >
          {[...Array(6)].map((_, i) => (
            <SelectItem key={i + 1} value={i + 1}>
              {`Last ${i + 1} ${i === 0 ? "month" : "months"}`}
            </SelectItem>
          ))}
        </Select>
      </div>
      <TabGroup>
        <TabList className="mt-2">
          <Tab onClick={() => setSelectedTab(0)}>By Cash/Credit</Tab>
          <Tab onClick={() => setSelectedTab(1)}>By Local/Imported</Tab>
        </TabList>
        <TabPanels>
          <TabPanel className="h-[160px]">
            <BarChart
              data={chartData}
              index="WeekStart"
              categories={["Cash", "Credit"]}
              colors={["green", "red"]}
              yAxisLabel="Number of Sales"
              stack={true}
              className="mt-2 h-full"
              yAxisWidth={20}
              showAnimation={true}
              showGridLines={true}
            />
          </TabPanel>
          <TabPanel className="h-[160px]">
            <BarChart
              data={chartData}
              index="WeekStart"
              categories={["Local", "Imported"]}
              colors={["red", "yellow"]}
              yAxisLabel="Number of Sales"
              stack={true}
              className="mt-2 h-full"
              yAxisWidth={20}
              showAnimation={true}
              showGridLines={true}
            />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </Card>
  );
};

const OutstandingCreditChart = () => {

  const [daysBack, setDaysBack] = useState(90); // Default: Last 90 days
  const [view, setView] = useState('outstanding'); // Default view: outstanding (alternatives: overdue, percentage)
  const { DealershipID } = useContext(GlobalContext);

  const { data: chartData = [], isLoading, isError, error } = useQuery({
    queryKey: ["creditTrend", DealershipID, daysBack],
    queryFn: async () => {
      const response = await fetch("https://api.autodealerug.com/getcredittrend", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        credentials: "include",
        body: JSON.stringify({
          DealershipID,
          daysBack,
        })
      });

      if (!response.ok) {
        throw new Error(`Server responded with status: ${response.status}`);
      }

      const result = await response.json();

      // Format the data for the chart
      return result.data.map((item, index) => {
        const date = new Date(item.date);

        // Calculate daily changes
        const dailyOutstandingChange = index > 0
          ? parseFloat(item.outstanding_credit_ugx) - parseFloat(result.data[index - 1].outstanding_credit_ugx)
          : 0;

        const dailyOverdueChange = index > 0
          ? parseFloat(item.overdue_credit_ugx) - parseFloat(result.data[index - 1].overdue_credit_ugx)
          : 0;

        return {
          date: date.toLocaleDateString("en-GB"),
          "Outstanding Credit": parseFloat(item.outstanding_credit_ugx),
          "Overdue Credit": parseFloat(item.overdue_credit_ugx),
          "Overdue Percentage": parseFloat(item.overdue_percentage),
          "Daily Outstanding Change": dailyOutstandingChange,
          "Daily Overdue Change": dailyOverdueChange
        };
      });
    },
    staleTime: 5 * 60 * 1000, // Cache data for 5 minutes
    refetchOnWindowFocus: false, // Prevent refetching on window focus
  });
  // Format UGX values for display
  const formatUGX = (value) => {
    if (value >= 1_000_000) {
      return `${(value / 1_000_000).toFixed(1)}M`;
    } else if (value >= 1_000) {
      return `${(value / 1_000).toFixed(1)}K`;
    } else {
      return `${value.toLocaleString()}`;
    }
  };

  // Format percentage values
  const formatPercentage = (value) => {
    return `${value.toFixed(2)}%`;
  };

  // Determine which data to display based on the selected view
  const getChartCategories = () => {
    switch (view) {
      case 'outstanding':
        return ["Outstanding Credit"];
      case 'overdue':
        return ["Outstanding Credit", "Overdue Credit"];
      case 'percentage':
        return ["Overdue Percentage"];
      case 'daily':
        return ["Daily Outstanding Change", "Daily Overdue Change"];
      default:
        return ["Outstanding Credit"];
    }
  };

  // Set colors based on view
  const getChartColors = () => {
    switch (view) {
      case 'outstanding':
        return ["indigo"];
      case 'overdue':
        return ["blue", "red"];
      case 'percentage':
        return ["amber"];
      case 'daily':
        return ["blue", "red"];
      default:
        return ["indigo"];
    }
  };

  // Get the appropriate value formatter based on the view
  const getValueFormatter = () => {
    if (view === 'percentage') {
      return formatPercentage;
    }
    return formatUGX;
  };

  return (
    <Card className="min-w-[450px]">
      <div className="flex justify-between items-center mb-2">
        <Title className="text-lg font-medium">Credit Exposure</Title>
        <div className="flex space-x-2">
          <Select value={daysBack.toString()} onValueChange={(value) => setDaysBack(Number(value))}>
            {[30, 60, 90, 180, 365].map((days) => (
              <SelectItem key={days} value={days.toString()}>
                Last {days} Days
              </SelectItem>
            ))}
          </Select>
        </div>
      </div>

      <TabGroup className="mt-2" value={view}>
        <TabList variant="solid">
          <Tab value="outstanding" onClick={() => setView("outstanding")}>Outstanding</Tab>
          <Tab value="overdue" onClick={() => setView("overdue")}>Overdue</Tab>
          <Tab value="percentage" onClick={() => setView("percentage")}>Overdue %</Tab>
          <Tab value="daily" onClick={() => setView("daily")}>Daily Change</Tab>
        </TabList>
      </TabGroup>

      {isLoading ? (
        <div className="h-[210px] flex items-center justify-center">
          <Text>Loading credit data...</Text>
        </div>
      ) : error ? (
        <div className="h-[210px] flex items-center justify-center">
          <Text color="red">{error}</Text>
        </div>
      ) : (
        <AreaChart
          className="h-[210px] mt-2"
          data={chartData}
          index="date"
          categories={getChartCategories()}
          colors={getChartColors()}
          yAxisWidth={60}
          showLegend={true}
          showAnimation={true}
          showGridLines={true}
          curveType="monotone"
          valueFormatter={getValueFormatter()}
        />
      )}

      {!isLoading && !error && chartData.length > 0 && (
        <div className="mt-2 grid grid-cols-2 gap-4 mt-2">
          <div className=" p-3 rounded-md">
            <Text className="text-sm text-gray-500">Current Outstanding</Text>
            <Text className="text-lg font-medium">
              {formatUGX(chartData[chartData.length - 1]["Outstanding Credit"])}
            </Text>
          </div>
          <div className=" p-3 rounded-md">
            <Text className="text-sm text-gray-500">Current Overdue</Text>
            <Text className="text-lg font-medium text-red-600">
              {formatUGX(chartData[chartData.length - 1]["Overdue Credit"])}
              <span className="text-sm ml-1 text-amber-600">
                ({chartData[chartData.length - 1]["Overdue Percentage"].toFixed(1)}%)
              </span>
            </Text>
          </div>
        </div>
      )}

      <Text className="mt-2 text-sm text-gray-500">
        {view === 'outstanding' && 'Total outstanding credit balance over time (UGX)'}
        {view === 'overdue' && 'Comparison of overdue vs total outstanding credit (UGX)'}
        {view === 'percentage' && 'Percentage of credit that is overdue (UGX)'}
        {view === 'daily' && 'Daily changes in outstanding and overdue credit amounts (UGX)'}
      </Text>
    </Card>
  );
};


// Helper function to format currency (UGX)
const formatUGX = (value) => {
  //If value is null or undefined, return 0
  if (value === null || value === undefined) {
    return '0.00';
  }
  //If value is an object, return 0
  if (typeof value === 'object') {
    return '0.00';
  }
  //If value is a string, convert to number
  if (typeof value === 'string') {
    value = parseFloat(value.replace(/,/g, ''));
  }
  
  if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + 'M';
  } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + 'K';
  } else {
      return value.toFixed(2);
  }
};

// Custom MetricCard component using standard Tremor components
const MetricCard = ({ title, value, delta, textClassName, className }) => {
    return (
        <Card className={className}>
            <Text>{title}</Text>
            <Metric>{value}</Metric>
            {delta && <Text className={textClassName}>{delta}</Text>}
        </Card>
    );
};

// Custom CardHeader and CardTitle for compatibility
const CardHeader = ({ children, className }) => (
    <div className={`p-6 pb-0 ${className || ''}`}>{children}</div>
);

const CardTitle = ({ children, className }) => (
  <h3 className={`text-lg font-medium flex items-center justify-center ${className || ''}`}>{children}</h3>
);

// Custom CardContent for compatibility
const CardContent = ({ children, className }) => (
    <div className={`p-4 ${className || ''}`}>{children}</div>
);

const CreditCollectionOverview = () => {
  const [displayMonths, setDisplayMonths] = useState(6);
  const [chartType, setChartType] = useState('sideBySide');
  const formatMonth = (monthStr) => {
    // Convert "YYYY-MM" format to "MMM YYYY"
    if (!monthStr) return '';
    const [year, month] = monthStr.split('-');
    const date = new Date(parseInt(year), parseInt(month) - 1);
    return date.toLocaleString('default', { month: 'short', year: 'numeric' });
  };

  const { DealershipID } = useContext(GlobalContext);

  const { data: chartData = [], isLoading, isError, error } = useQuery({
    queryKey: ["creditCollectionTrend", displayMonths],
    queryFn: async () => {
      const response = await fetch("https://api.autodealerug.com/getcreditcollectiontrend", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        credentials: "include",
        body: JSON.stringify({ DealershipID, months: displayMonths })
      });

      if (!response.ok) {
        throw new Error(`Server responded with status: ${response.status}`);
      }

      const result = await response.json();
      return result.data;
    },
    staleTime: 1 * 60 * 1000, // Cache data for 5 minutes
    refetchOnWindowFocus: false, // Prevent refetching on window focus
  });
  
  const [selectedCategory, setSelectedCategory] = useState('total');
  
  // Calculate KPIs for the current month (using the last entry in the filtered data)
  const currentMonthData = chartData[chartData.length - 2];
  const lastMonthData = chartData[chartData.length - 3] || currentMonthData;
  
  const currentDueCollected = parseFloat(currentMonthData?.DueCollected || 0);
  const currentOverdueCollected = parseFloat(currentMonthData?.OverdueCollected || 0);
  const currentDueTarget = parseFloat(currentMonthData?.DueTarget || 0);
  const currentOverdueTarget = parseFloat(currentMonthData?.OverdueTarget || 0);
  const lastDueCollected = parseFloat(lastMonthData?.DueCollected || 0);
  const lastOverdueCollected = parseFloat(lastMonthData?.OverdueCollected || 0);
  
  // Calculate totals
  const currentTotalCollected = currentDueCollected + currentOverdueCollected;
  const currentTotalTarget = currentDueTarget + currentOverdueTarget;
  const lastTotalCollected = lastDueCollected + lastOverdueCollected;

  // Add check to prevent division by zero
  const dueCollectedDelta = lastDueCollected === 0 ? 0 : ((currentDueCollected - lastDueCollected) / lastDueCollected) * 100;
  const overdueCollectedDelta = lastOverdueCollected === 0 ? 0 : ((currentOverdueCollected - lastOverdueCollected) / lastOverdueCollected) * 100;
  const totalCollectedDelta = lastTotalCollected === 0 ? 0 : ((currentTotalCollected - lastTotalCollected) / lastTotalCollected) * 100;

  // Collection performance as percentage of target
  const duePerformance = currentDueTarget === 0 ? 0 : (currentDueCollected / currentDueTarget) * 100;
  const overduePerformance = currentOverdueTarget === 0 ? 0 : (currentOverdueCollected / currentOverdueTarget) * 100;
  const totalPerformance = currentTotalTarget === 0 ? 0 : (currentTotalCollected / currentTotalTarget) * 100;
  
  // Transform data for side-by-side bars or percentage view based on selected category
  const transformedData = useMemo(() => {
    return chartData.map(item => {
      const dueTarget = parseFloat(item.DueTarget || 0);
      const dueCollected = parseFloat(item.DueCollected || 0);
      const overdueTarget = parseFloat(item.OverdueTarget || 0);
      const overdueCollected = parseFloat(item.OverdueCollected || 0);
      const totalTarget = dueTarget + overdueTarget;
      const totalCollected = dueCollected + overdueCollected;
      
      // Calculate performance percentages
      const duePercentage = dueTarget === 0 ? 0 : (dueCollected / dueTarget) * 100;
      const overduePercentage = overdueTarget === 0 ? 0 : (overdueCollected / overdueTarget) * 100;
      const totalPercentage = totalTarget === 0 ? 0 : (totalCollected / totalTarget) * 100;
      //console.log(item);
      return {
        // Format month for display
        month: formatMonth(item.month),
        // Data for due category
        "Due Target": dueTarget,
        "Due Collected": dueCollected,
        "Due Performance": duePercentage,
        
        // Data for overdue category
        "Overdue Target": overdueTarget,
        "Overdue Collected": overdueCollected,
        "Overdue Performance": overduePercentage,
        
        // Data for total category
        "Total Target": totalTarget,
        "Total Collected": totalCollected,
        "Total Performance": totalPercentage,
        
        // Raw values for tooltips
        "rawDueTarget": dueTarget,
        "rawDueCollected": dueCollected,
        "rawOverdueTarget": overdueTarget,
        "rawOverdueCollected": overdueCollected,
        "rawTotalTarget": totalTarget,
        "rawTotalCollected": totalCollected
      };
    });
  }, [chartData]);
  // Define chart categories based on selected view and category
  const chartCategories = useMemo(() => {
    if (chartType === 'sideBySide') {
      switch (selectedCategory) {
        case 'due':
          return ["Due Target", "Due Collected"];
        case 'overdue':
          return ["Overdue Target", "Overdue Collected"];
        case 'total':
          return ["Total Target", "Total Collected"];
        default:
          return ["Total Target", "Total Collected"];
      }
    } else { // percentage view
      switch (selectedCategory) {
        case 'due':
          return ["Due Performance"];
        case 'overdue':
          return ["Overdue Performance"];
        case 'total':
          return ["Total Performance"];
        default:
          return ["Total Performance"];
      }
    }
  }, [selectedCategory, chartType]);

  // Define chart colors based on selected view and category
  const chartColors = useMemo(() => {
    if (chartType === 'sideBySide') {
      switch (selectedCategory) {
        case 'due':
          return ["blue", "green"];
        case 'overdue':
          return ["indigo", "emerald"];
        case 'total':
          return ["blue", "green"];
        default:
          return ["blue", "green"];
      }
    } else { // percentage view
      switch (selectedCategory) {
        case 'due':
          return ["green"];
        case 'overdue':
          return ["emerald"];
        case 'total':
          return ["blue"];
        default:
          return ["blue"];
      }
    }
  }, [selectedCategory, chartType]);

  // Value formatter for the chart
  const valueFormatter = (value) => {
    if (chartType === 'percentage') {
      return `${value.toFixed(1)}%`;
    }
    return formatUGX(value);
  };

  // Get metrics to display based on selected category
  const getMetricsForCategory = () => {
    switch (selectedCategory) {
      case 'due':
        return [
          {
            title: "Due Collected",
            value: `UGX ${formatUGX(currentDueCollected)}`,
            delta: `${dueCollectedDelta > 0 ? '+' : ''}${dueCollectedDelta.toFixed(1)}% from last month`,
            textClassName: dueCollectedDelta > 0 ? "text-green-500" : "text-red-500",
            performance: duePerformance,
            target: currentDueTarget
          }
        ];
      case 'overdue':
        return [
          {
            title: "Overdue Collected",
            value: `UGX ${formatUGX(currentOverdueCollected)}`,
            delta: `${overdueCollectedDelta > 0 ? '+' : ''}${overdueCollectedDelta.toFixed(1)}% from last month`,
            textClassName: overdueCollectedDelta > 0 ? "text-green-500" : "text-red-500",
            performance: overduePerformance,
            target: currentOverdueTarget
          }
        ];
      case 'total':
      default:
        return [
          {
            title: "Due Collected",
            value: `UGX ${formatUGX(currentDueCollected)}`,
            delta: `${dueCollectedDelta > 0 ? '+' : ''}${dueCollectedDelta.toFixed(1)}% from last month`,
            textClassName: dueCollectedDelta > 0 ? "text-green-500" : "text-red-500",
            performance: duePerformance,
            target: currentDueTarget
          },
          {
            title: "Overdue Collected",
            value: `UGX ${formatUGX(currentOverdueCollected)}`,
            delta: `${overdueCollectedDelta > 0 ? '+' : ''}${overdueCollectedDelta.toFixed(1)}% from last month`,
            textClassName: overdueCollectedDelta > 0 ? "text-green-500" : "text-red-500",
            performance: overduePerformance,
            target: currentOverdueTarget
          },
          {
            title: "Total Collected",
            value: `UGX ${formatUGX(currentTotalCollected)}`,
            delta: `${totalCollectedDelta > 0 ? '+' : ''}${totalCollectedDelta.toFixed(1)}% from last month`,
            textClassName: totalCollectedDelta > 0 ? "text-green-500" : "text-red-500",
            performance: totalPerformance,
            target: currentTotalTarget
          }
        ];
    }
  };

  const metrics = getMetricsForCategory();

  return (
    <div className="p-4 space-y-8  w-full">
      <h1 className="text-2xl font-bold text-gray-800">Financial Performance Overview</h1>

      {/* Category Selector */}
      <div className="flex justify-end space-x-2 mt-2">
        <TabGroup value={selectedCategory} >
          <TabList variant="solid">
            <Tab value="total" onClick={()=>setSelectedCategory("total")}>Total</Tab>
            <Tab value="due" onClick={()=>setSelectedCategory("due")}>Due</Tab>
            <Tab value="overdue" onClick={()=>setSelectedCategory("overdue")}>Overdue</Tab>
          </TabList>
        </TabGroup>
      </div>

      {/* KPIs */}
        <div className={`grid grid-cols-1 md:grid-cols-${metrics.length} gap-2 mt-1`}>
          {metrics.map((metric, index) => (
            <MetricCard
          key={index}
          title={metric.title}
          value={metric.value}
          delta={metric.delta}
          textClassName={metric.textClassName}
          className={`p-3 bg-white border-gray-200 ${metric.performance >= 90 ? 'border-l-4 border-l-green-500' : 
            metric.performance >= 60 ? 'border-l-4 border-l-yellow-500' : 'border-l-4 border-l-red-500'}`}
            />
          ))}
          
      {/* Performance Card */}
        {selectedCategory !== 'total' || metrics.length < 3 ? (
          <MetricCard
            title={`${selectedCategory === 'due' ? 'Due' : selectedCategory === 'overdue' ? 'Overdue' : 'Total'} Performance`}
            value={`${selectedCategory === 'due' ? duePerformance.toFixed(1) : 
              selectedCategory === 'overdue' ? overduePerformance.toFixed(1) : 
              totalPerformance.toFixed(1)}%`}
            delta={`Target: UGX ${formatUGX(selectedCategory === 'due' ? currentDueTarget : 
              selectedCategory === 'overdue' ? currentOverdueTarget : 
              currentTotalTarget)}`}
            textClassName="text-gray-500"
            className={`bg-white border-gray-200 ${
              (selectedCategory === 'due' ? duePerformance : 
                selectedCategory === 'overdue' ? overduePerformance : 
                totalPerformance) >= 90 ? 'border-l-4 border-l-green-500' : 
              (selectedCategory === 'due' ? duePerformance : 
                selectedCategory === 'overdue' ? overduePerformance : 
                totalPerformance) >= 60 ? 'border-l-4 border-l-yellow-500' : 'border-l-4 border-l-red-500'}`}
          />
        ) : null}
      </div>

      {/* Collection Chart */}
      <Card className="p-2 bg-white border-gray-200 shadow-sm mt-3">
        <CardHeader className="p-2 flex flex-col sm:flex-row sm:items-center sm:justify-between">
          <CardTitle className="text-gray-800 sm:mb-0">Collection Progress</CardTitle>
          <div className="flex flex-col sm:flex-row gap-4">
            <Select 
              value={displayMonths.toString()} 
              onValueChange={(value) => setDisplayMonths(parseInt(value))}
              className="w-40"
            >
              <SelectItem value="3">Last 3 Months</SelectItem>
              <SelectItem value="6">Last 6 Months</SelectItem>
              <SelectItem value="8">All 8 Months</SelectItem>
            </Select>
            <Select 
              value={chartType} 
              onValueChange={setChartType}
              className="w-52"
            >
              <SelectItem value="sideBySide">Side-by-Side View</SelectItem>
              <SelectItem value="percentage">Performance Percentage</SelectItem>
            </Select>
          </div>
        </CardHeader>

        <CardContent>
          <BarChart
            data={transformedData}
            index="month"
            categories={chartCategories}
            colors={chartColors}
            valueFormatter={valueFormatter}
            showLegend={true}
            showGridLines={true}
            showAnimation={true}
            className="h-80"
            customTooltip={({ payload }) => {
              if (!payload[0]) return null;
              const data = payload[0].payload;
              
              const getTooltipContent = () => {
                if (chartType === 'percentage') {
                  switch (selectedCategory) {
                    case 'due':
                      return (
                        <>
                          <p className="font-medium text-gray-800">{data.month}</p>
                          <div className="mt-2 space-y-1">
                            <p className="text-sm">
                              <span className="font-medium text-green-600">Due Performance: </span>
                              {data["Due Performance"].toFixed(1)}%
                            </p>
                            <p className="text-sm text-gray-500">
                              Collected: UGX {formatUGX(data.rawDueCollected)}
                            </p>
                            <p className="text-sm text-gray-500">
                              Target: UGX {formatUGX(data.rawDueTarget)}
                            </p>
                          </div>
                        </>
                      );
                    case 'overdue':
                      return (
                        <>
                          <p className="font-medium text-gray-800">{data.month}</p>
                          <div className="mt-2 space-y-1">
                            <p className="text-sm">
                              <span className="font-medium text-emerald-600">Overdue Performance: </span>
                              {data["Overdue Performance"].toFixed(1)}%
                            </p>
                            <p className="text-sm text-gray-500">
                              Collected: UGX {formatUGX(data.rawOverdueCollected)}
                            </p>
                            <p className="text-sm text-gray-500">
                              Target: UGX {formatUGX(data.rawOverdueTarget)}
                            </p>
                          </div>
                        </>
                      );
                    case 'total':
                    default:
                      return (
                        <>
                          <p className="font-medium text-gray-800">{data.month}</p>
                          <div className="mt-2 space-y-1">
                            <p className="text-sm">
                              <span className="font-medium text-blue-600">Total Performance: </span>
                              {data["Total Performance"].toFixed(1)}%
                            </p>
                            <p className="text-sm text-gray-500">
                              Total Collected: UGX {formatUGX(data.rawTotalCollected)}
                            </p>
                            <p className="text-sm text-gray-500">
                              Total Target: UGX {formatUGX(data.rawTotalTarget)}
                            </p>
                            <hr className="my-1" />
                            <p className="text-sm text-gray-500">
                              Due: UGX {formatUGX(data.rawDueCollected)} / {formatUGX(data.rawDueTarget)} 
                              ({data["Due Performance"].toFixed(1)}%)
                            </p>
                            <p className="text-sm text-gray-500">
                              Overdue: UGX {formatUGX(data.rawOverdueCollected)} / {formatUGX(data.rawOverdueTarget)} 
                              ({data["Overdue Performance"].toFixed(1)}%)
                            </p>
                          </div>
                        </>
                      );
                  }
                } else { // sideBySide view
                  switch (selectedCategory) {
                    case 'due':
                      return (
                        <>
                          <p className="font-medium text-gray-800">{data.month}</p>
                          <div className="mt-2 space-y-1">
                            <p className="text-sm">
                              <span className="font-medium text-blue-600">Due Target: </span>
                              UGX {formatUGX(data["Due Target"])}
                            </p>
                            <p className="text-sm">
                              <span className="font-medium text-green-600">Due Collected: </span>
                              UGX {formatUGX(data["Due Collected"])}
                            </p>
                            <p className="text-sm text-gray-500">
                              Performance: {data["Due Performance"].toFixed(1)}%
                            </p>
                          </div>
                        </>
                      );
                    case 'overdue':
                      return (
                        <>
                          <p className="font-medium text-gray-800">{data.month}</p>
                          <div className="mt-2 space-y-1">
                            <p className="text-sm">
                              <span className="font-medium text-indigo-600">Overdue Target: </span>
                              UGX {formatUGX(data["Overdue Target"])}
                            </p>
                            <p className="text-sm">
                              <span className="font-medium text-emerald-600">Overdue Collected: </span>
                              UGX {formatUGX(data["Overdue Collected"])}
                            </p>
                            <p className="text-sm text-gray-500">
                              Performance: {data["Overdue Performance"].toFixed(1)}%
                            </p>
                          </div>
                        </>
                      );
                    case 'total':
                    default:
                      return (
                        <>
                          <p className="font-medium text-gray-800">{data.month}</p>
                          <div className="mt-2 space-y-1">
                            <p className="text-sm">
                              <span className="font-medium text-blue-600">Total Target: </span>
                              UGX {formatUGX(data["Total Target"])}
                            </p>
                            <p className="text-sm">
                              <span className="font-medium text-green-600">Total Collected: </span>
                              UGX {formatUGX(data["Total Collected"])}
                            </p>
                            <p className="text-sm text-gray-500">
                              Performance: {data["Total Performance"].toFixed(1)}%
                            </p>
                            <hr className="my-1" />
                            <p className="text-sm text-gray-500">
                              Due: UGX {formatUGX(data.rawDueCollected)} / {formatUGX(data.rawDueTarget)}
                            </p>
                            <p className="text-sm text-gray-500">
                              Overdue: UGX {formatUGX(data.rawOverdueCollected)} / {formatUGX(data.rawOverdueTarget)}
                            </p>
                          </div>
                        </>
                      );
                  }
                }
              };
              
              return (
                <div className="bg-white p-4 shadow-lg rounded-lg border border-gray-200">
                  {getTooltipContent()}
                </div>
              );
            }}
          />
        </CardContent>
      </Card>
    </div>
  );
};



