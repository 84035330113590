import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App.jsx'
import { Theme } from "@radix-ui/themes";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import "./carstyles.css";
import "@radix-ui/themes/styles.css";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Theme
    accentColor="mint"
    grayColor="gray"
    panelBackground="solid"
    scaling="100%"
    radius="full"
  >
    <QueryClientProvider client={new QueryClient()}>
      <App />
    </QueryClientProvider>
  </Theme>
  
);