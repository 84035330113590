import { EventEmitter } from 'events';
export const emitter = new EventEmitter();

const UtilityFunctions = {
  formatFromInput: function(value, fieldType){
    //Casts strings from input fields from which numbers are expected
    //By removing commas and casting into the number type
    //Mostly integers, decimals, and dates
    //For integers and decimals, remove commas and cast the field into a Number
    //For dates, split and parse it ad yyyy-mm-dd
    //For null values, return an empty string
    if(value===null || value===undefined) return "";

    switch(fieldType){
      case "integer":
        return typeof(value)==="string"?Number(value.replace(/[^0-9.]/g, '')):value;
      case "decimal":
        return typeof(value)==="string"?Number(value.replace(/[^0-9.]/g, '')):value;
      case "date":
        return value.split("T")[0];
      case "boolean":
        return value===1?true:false;
      default:
        return value; //Strings
    }
  },
  formatIntoInput: function(value, fieldType){
    //Formats data before it's displayed in input fields
    //For numbers, it adds commas
    switch(fieldType){
      case "decimal":
          return new Intl.NumberFormat().format(value);
      case "date":
        //If the value is null or undefined, return an empty string
        if(value===null || value===undefined) return "";
        return value.split("T")[0];
      case "integer":
          return new Intl.NumberFormat().format(value);
      default:
          return value;
    }
  },
  getDaysDifference: function(date1, date2) {
    // Convert the input dates to Date objects if they are not already
    date1 = new Date(date1);
    date2 = new Date(date2);

    // Calculate the difference in milliseconds
    let differenceInMilliseconds = date2 - date1;

    // Convert milliseconds to days and return the result
    return Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
  },
  sortArray: (array, field, order)=>{
    return array.sort((a, b) => {
        // Compare the field values
        const valueA = a[field];
        const valueB = b[field];
        
        // Sort in ascending order
        if (order === 'asc') {
            if (valueA < valueB) return -1;
            if (valueA > valueB) return 1;
            return 0;
        }
        // Sort in descending order
        else if (order === 'desc') {
            if (valueA < valueB) return 1;
            if (valueA > valueB) return -1;
            return 0;
        }
        return 0;
    });
  },
  sendOrQueue: (msg, sock, queue, data, backup, setter)=>{
    //Attempts to send a message. If it fails, it queues it.
    if(sock.connected) {
      //Check msg.triggerRefresh
      if(msg.triggerRefresh){ //Send "update_record" and "replace_record"
        sock.emit('updatefield', {
          ...msg,
          eventArr: [
            {
              eventName: 'update_record', //Of subSheet
              eventProperties: {
                ownSheetName: msg.ownSheetName, //Self
                primaryKeyName: msg.primaryKeyName,
                primaryKeyValue: msg.primaryKeyValue,
                fieldName: msg.fieldName,
                fieldValue: msg.fieldValue,
              }
            },
            {
              eventName: 'replace_record', //Of main sheet
              eventProperties: { //If it has a parent, replace the parent's record. Else, replace "own" i.e immediate sheet.
                ownSheetName: msg.parentSheetName?msg.parentSheetName:msg.ownSheetName, //Parent
                primaryKeyName: msg.parentPKeyName?msg.parentPKeyName:msg.primaryKeyName,
                primaryKeyValue: msg.parentPKeyValue?msg.parentPKeyValue:msg.primaryKeyValue
              }
            }
          ]
        });
      } else { //Send only "update_record"
        sock.emit('updatefield', {
          ...msg,
          eventArr: [
            {
              eventName: 'update_record', //Of subsheet
              eventProperties: {
                ownSheetName: msg.ownSheetName,
                primaryKeyName: msg.primaryKeyName,
                primaryKeyValue: msg.primaryKeyValue,
                fieldName: msg.fieldName,
                fieldValue: msg.fieldValue
              }
            },
            (()=>{
              if(msg.parentSheetName){
                return {
                  eventName: 'update_record', //Of main sheet
                  eventProperties: {
                    ownSheetName: msg.parentSheetName,
                    primaryKeyName: msg.parentPKeyName,
                    primaryKeyValue: msg.parentPKeyValue,
                    fieldName: msg.fieldName,
                    fieldValue: msg.fieldValue
                  }
                }
              }
            })() //If it has a parent, send parent event too
          ]
        });
      }
    } else { //If socket is active, keep changes. Else, revert to fallback
      console.log("Socket not connected.");  
      if(!sock.active) { //If socket is inactive OR queue has accumulated more than 5 events, rollback
        console.log("Socket not active or event buffer full.")
        //If the event buffer is full, TODO: Signal the lack of a connection
        
            setter({...data, [msg.fieldName]: backup[msg.fieldName]});
        } else {
            //TODO: Queue the message for re-emiting when connected.
            console.log("Socket active. Queueing.");
            if(queue.length <=4) {
              queue.push(msg);
            } else {
              //Emit a no connection event or just show the msgBox
              emitter.emit("server-disconnected")
            }
            
            console.log(`Queue length: ${queue.length}`);
        }
    }
  },
}
const passesFilter = (value, filterObj, datatype) => {
  if (!filterObj || !filterObj.filterType) return true; // No filter, always passes

  const { filterType, lowerValue, upperValue } = filterObj;

  if (datatype === "decimal" || datatype === "integer" || datatype === "date") {
      if (datatype === "date"){
        value = new Date(value).getTime(); // Convert date to timestamp
      } else { //The value could come as a string
        value = Number.parseFloat(value);
      }

      const filterVal1 = datatype === "date" ? new Date(lowerValue).getTime() : parseFloat(lowerValue);
      const filterVal2 = upperValue ? (datatype === "date" ? new Date(upperValue).getTime() : parseFloat(upperValue)) : null;
      //console.log(`Value: ${value}, filterType: ${filterType} filterVal1: ${lowerValue}, filterVal2: ${upperValue}`);
      switch (filterType) {
          case '>': return value > filterVal1;
          case '>=': return value >= filterVal1;
          case '<': return value < filterVal1;
          case '<=': return value <= filterVal1;
          case '=': return value === filterVal1;
          case '!=': return value !== filterVal1;
          case 'between': return filterVal2 !== null ? (value >= filterVal1 && value <= filterVal2) : false;
          default: return true; // Unknown filter type, let it pass
      }
  } else if (datatype === "string") {
      return value.toLowerCase().includes(lowerValue.toLowerCase()); // Case-insensitive match
  }

  return false; // If the datatype doesn't match, return false
};


function generateBase64String() {
  // Generate 12 random bytes (16 Base64 characters = 12 bytes)
  const randomBytes = crypto.getRandomValues(new Uint8Array(12));
  // Convert to Base64 string
  const base64String = btoa(String.fromCharCode(...randomBytes));
  // Remove padding characters and return the result
  return base64String.replace(/=/g, '');
}

async function generateKey(salt) {
  const encoder = new TextEncoder();
  const keyMaterial = await crypto.subtle.importKey(
      "raw",
      encoder.encode(salt),
      { name: "PBKDF2" },
      false,
      ["deriveKey"]
  );

  return crypto.subtle.deriveKey(
      {
          name: "PBKDF2",
          salt: encoder.encode(salt), // Salting for extra security
          iterations: 100000, // Recommended for PBKDF2
          hash: "SHA-256"
      },
      keyMaterial,
      { name: "AES-GCM", length: 256 },
      true,
      ["encrypt", "decrypt"]
  );
}

async function encrypt(text, salt) {
  const key = await generateKey(salt);
  const iv = crypto.getRandomValues(new Uint8Array(12)); // Generate a random IV
  const encoder = new TextEncoder();
  const encryptedData = await crypto.subtle.encrypt(
      { name: "AES-GCM", iv },
      key,
      encoder.encode(text)
  );

  return `${btoa(String.fromCharCode(...new Uint8Array(encryptedData)))}:${btoa(String.fromCharCode(...iv))}`
}

async function decrypt(encryptedText, salt) {
  if(!encryptedText) return "";
  const [ encryptedBase64, ivBase64 ] = encryptedText.split(":");
  const key = await generateKey(salt);
  const iv = new Uint8Array(atob(ivBase64).split("").map(c => c.charCodeAt(0)));
  const encryptedData = new Uint8Array(atob(encryptedBase64).split("").map(c => c.charCodeAt(0)));

  const decryptedBuffer = await crypto.subtle.decrypt(
      { name: "AES-GCM", iv },
      key,
      encryptedData
  );

  return new TextDecoder().decode(decryptedBuffer);
}

//Create a function to generate a temporary password for the user
function generatePassword() {
  // Generate a random 8-character password
  const randomBytes = crypto.getRandomValues(new Uint8Array(10));
  const password = btoa(String.fromCharCode(...randomBytes)).replace(/=/g, '');
  return password;
}

export default UtilityFunctions;
export { generateBase64String, passesFilter, encrypt, decrypt, generatePassword };